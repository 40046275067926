<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="page_links">
					当前位置：
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/ucenter' }">店铺管理</el-breadcrumb-item>
						<el-breadcrumb-item>开通会员</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="vip_content">
					<p class="title">会员尊享特权</p>
					<p class="subtxt">VIP不限制订单量；普通会员0费用，每天限采购10单</p>
					<div class="sel_tabs">
						<div class="tab active">
							<p class="name">月VIP</p>
							<p class="price"><em>299</em>元/月</p>
						</div>
						<div class="tab">
							<p class="name">季VIP</p>
							<p class="price"><em>799</em>元/季</p>
						</div>
						<div class="tab">
							<p class="name">半年VIP</p>
							<p class="price"><em>1399</em>元/半年</p>
						</div>
						<div class="tab">
							<p class="name">年VIP</p>
							<p class="price"><em>2499</em>元/年</p>
						</div>
					</div>
					<div class="pay_wrap">
						<p class="price">应付金额：299元</p>
						<p class="des">支付即同意<a href="#">《VIP服务协议》</a></p>
						<div class="code_wrap">
							<div class="code_box">
								<img class="code" src="../../assets/images/code.png" />
								<p class="pay_name">
									<img class="icon" src="../../assets/images/wechat.png" />
									微信支付
								</p>
							</div>
							<div class="code_box">
								<img class="code" src="../../assets/images/code.png" />
								<p class="pay_name">
									<img class="icon" src="../../assets/images/zhifubao.png" />
									支付宝支付
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
export default {
	name: "vipBuy",
	data() {
		return {
			
			
		}
	},
	components:{
		myHead,myFoot
	},
	created(){
		
	},
	methods: {
		
	}
}
</script>

<style scoped>
.vip_content{padding: 70px;background: #fff;line-height: 1;color: #333;margin-top: 30px;}
.vip_content .title{font-size: 36px;font-weight: bold;text-align: center;}
.vip_content .subtxt{font-size: 16px;margin-top: 20px;text-align: center;}
.vip_content .sel_tabs{margin-top: 50px;display: flex;justify-content: space-between;}
.vip_content .sel_tabs .tab{width: 250px;height: 150px;box-sizing: border-box;border: 1px solid #ccc;cursor: pointer;text-align: center;}
.vip_content .sel_tabs .tab.active{border: 2px solid #e4393c;}
.vip_content .sel_tabs .tab .name{font-size: 20px;margin-top: 34px;}
.vip_content .sel_tabs .tab .price{margin-top: 25px;font-size: 16px;color: #999;}
.vip_content .sel_tabs .tab .price>em{font-size: 40px;color: #333333;}
.vip_content .sel_tabs .tab.active .price>em{color: #e4393c;}
.vip_content .pay_wrap{margin-top: 50px;height: 400px;background: #f8f8f8;text-align: center;}
.vip_content .pay_wrap .price{font-size: 22px;color: #e4393c;padding-top: 50px;}
.vip_content .pay_wrap .des{font-size: 14px;color: #999999;margin-top: 16px;}
.vip_content .pay_wrap .des a{color: #999999;}
.vip_content .pay_wrap .des a:hover{color: #e4393c;text-decoration: underline;}
.vip_content .pay_wrap .code_wrap{margin-top: 40px;display: flex;justify-content: center;}
.vip_content .pay_wrap .code_wrap .code_box{width: 170px;margin: 0 115px;}
.vip_content .pay_wrap .code_wrap .code_box .code{width: 170px;height: 170px;}
.vip_content .pay_wrap .code_wrap .code_box .pay_name{display: flex;align-items: center;justify-content: center;margin-top: 16px;font-size: 14px;}
.vip_content .pay_wrap .code_wrap .code_box .pay_name .icon{width: 22px;height: 22px;margin-right: 10px;}



</style>
<style>
.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
.el-table .el-table_1_column_2 .cell .num{color: #999;}


</style>